// hide scrollbar but keep function
/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

// home buttons
.btn-home-primary {
  @apply block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-blue-700 hover:bg-gray-50 sm:inline-block sm:w-auto;
}

// normal buttons
.btn-primary {
  @apply ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

.btn-secondary {
  @apply inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

.btn-danger {
  @apply bg-white py-2 px-4 border border-red-400 rounded-md shadow-sm text-sm font-medium text-red-600 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
}

// prequalify buttons
.btn-pq-primary {
  @apply inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700;
}
.btn-pq-secondary {
  @apply inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200;
}

// links
.link-primary {
  @apply text-blue-600 hover:text-blue-900;
}

// nav links
.nav-link {
  @apply text-cyan-100 text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10;
}
.nav-link.active {
  @apply text-white;
}

// mobile nav links
.mob-nav-link {
  @apply block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800;
}
.mob-nav-link.active {

}

// user pop up menu links
.pop-menu-link {
  @apply block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100;
}

// form inputs
.form-label {
  @apply block text-sm font-medium text-gray-700;
}
.form-input {
  @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}
.form-select {
  @apply mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md;
}
.form-checkbox-label {
  @apply font-medium text-gray-700;
}
.form-checkbox {
  @apply focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded;
}
.form-radio-label {
  @apply ml-3 block text-sm font-medium text-gray-700;
}
.form-radio {
  @apply focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300;
}

// contact form input
.contact-input {
  @apply block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md;
}

// select for calculator
.s401k-state-select {
  @apply block w-full pl-3 text-base font-medium text-gray-500 border-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md;
}

// user form inputs
.user-form-row {
  @apply sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5;
}
.user-form-label {
  @apply block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2;
}
.user-form-input-div {
  @apply mt-1 sm:mt-0 sm:col-span-2;
}
.user-form-input {
  @apply block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md;
}

// form errors
form span.error {
  @apply mt-2 text-sm text-red-600;
}

.error_notification {
  @apply col-span-full rounded-md bg-red-50 px-12 py-4 text-sm font-medium text-red-800;
}

.field_with_errors input, .field_with_errors select, .field_with_errors textarea {
  @apply border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500;
}

//.field_without_errors input {
//  @apply border-green-400 text-green-900 placeholder-green-300 focus:ring-green-500 focus:border-green-500;
//}
//
//.field_without_errors input.disabled {
//  @apply text-gray-400;
//}

// list table
.list-table {}
.list-table .table-cell {

}
.list-table .table-header {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}


// calc table
.cal-401k-table {}
.cal-401k-table .table-header {
  @apply w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left align-top;
}
.cal-401k-table .info-text {
  @apply block text-xs text-gray-500;
}
.cal-401k-table .feature-header {
  @apply bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left;
}
.cal-401k-table .table-cell-header {
  @apply py-5 px-6 text-sm font-normal text-gray-500 text-left;
}
.cal-401k-table .table-cell {
  @apply py-5 px-6;
}
.cal-401k-table .cell-text {
  @apply block text-sm text-gray-700;
}
.cal-401k-table .cell-border-top {
  @apply border-t-2 border-gray-800;
}
.cal-401k-table .table-cell-feature {
  @apply py-8 px-6 text-sm font-medium text-gray-900 text-left align-top;
}

// calc table feature section
.savings-amount-gray {
  @apply text-2xl font-extrabold text-gray-600;
}
.savings-detail-gray {
  @apply absolute bottom-0 flex-grow block w-full bg-gray-600 border border-gray-600 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-800;
}
.savings-amount {
  @apply text-2xl font-extrabold text-green-600;
}
.savings-detail {
  @apply mt-6 flex-grow block w-full bg-green-600 border border-green-600 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-green-800;
}
.total-tax {
  @apply text-xl font-bold;
}


// page styles - app form
.app_status {
  @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;
}
.app_status.processing {
  @apply bg-blue-100 text-blue-800;
}
.app_status.active {
  @apply bg-green-100 text-green-800;
}
.app_status.suspend {
  @apply bg-red-100 text-red-800;
}

// video-js
.video-js {
  background-color: #ffffff;
}
// video tabs
.vtab {
  @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 py-4 px-1 text-center border-b-2 font-medium text-sm;
}
.vtab.active {
  @apply border-sky-500 text-sky-600 hover:text-sky-600 hover:border-sky-500;
}

// search fields
.search-field {
  @apply focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300;
}

// pagy
.pagy-nav,
.pagy-nav-js,
.pagy-combo-nav-js {
  @apply relative z-0 inline-flex rounded-md shadow-sm -space-x-px;
}
.pagy-nav.pagination,
.pagy-nav-js.pagination,
.pagy-combo-nav-js.pagination {

}
.pagy-nav .page,
.pagy-nav-js .page,
.pagy-combo-nav-js .page,
.pagy-combo-nav-js .pagy-combo-input {
  @apply bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium;
}
.pagy-nav .page:hover,
.pagy-nav-js .page:hover {

}
.pagy-nav .disabled,
.pagy-nav-js .disabled,
.pagy-combo-nav-js .disabled {
  @apply cursor-not-allowed;
}
.pagy-nav .active,
.pagy-nav-js .active {
  @apply z-10 bg-sky-50 border-sky-500 text-sky-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium;
}
.pagy-nav .prev,
.pagy-nav-js .prev,
.pagy-combo-nav-js .prev {
  @apply relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
}
.pagy-nav .next,
.pagy-nav-js .next,
.pagy-combo-nav-js .next {
  @apply relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
}