// include tailwindcss
@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

// flatpicker
@import "~flatpickr/dist/flatpickr.min";

// tippy.js
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';

// video.js
@import 'video.js/dist/video-js.css';
@import '@videojs/themes/dist/sea/index.css';

// this file is where to add custom CSS
@import "custom";
